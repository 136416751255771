<template>
	<div class="order-details">
		<div class="top">
			<span>{{ statusInfo.statusText }}</span>
			<img :src="require('@/assets/' + statusInfo.icon + '.png')" alt="" v-if="statusInfo.icon">
		</div>

		<div class="address-box">
			<img class="address-img" src="@/assets/address.png" alt="">
			<div class="mid">
				<p>{{ details.address_contacts }} <span>{{ details.address_phone }}</span></p>
				<p>{{ details.address_details }}</p>
			</div>
			<img class="right-img" src="@/assets/right.png" alt="">
		</div>

		<div class="goods-list">
			<router-link tag="div" class="goods-item" :to="'/GoodsDetails?id=' + goods.goods_id">
				<img :src="goods.goods_image" alt="">
				<div class="goods-info">
					<p class="goods-name">{{ goods.goods_name }}</p>
					<div style="display: flex; align-items: center; justify-content: space-between;">
						<div class="goods-spec" v-if="goods.specs_item_value != ''">{{ goods.specs_item_value }}</div>
						<div class="goods-price"><span>{{ goods.buy_points }}</span>积分</div>
						<div class="goods-num">×{{ goods.goods_nums }}</div>
					</div>
				</div>
			</router-link>
		</div>

		<div class="total">
			<div class="total-row">
				<div class="left">积分合计</div>
				<div class="right">{{ details.use_points }}积分</div>
			</div>
			<div class="total-row">
				<div class="left">商品价格</div>
				<div class="right">￥{{ details.goods_amount }}</div>
			</div>
			<div class="total-row">
				<div class="left">商品运费</div>
				<div class="right">￥{{ details.postage_amount }}</div>
			</div>
		</div>
		

		<div class="info-box">
			<div class="info-box-title">订单信息</div>
			<div class="info-row">订单编号： {{ details.order_no }}</div>
			<div class="info-row">创建时间： {{ details.create_time }}</div>
			<div class="info-row" v-if="details.order_status > 1">兑换时间： {{ details.pay_time }}</div>
			<template v-if="details.order_status > 2">
				<div class="info-row">发货时间： {{ details.delivery_time }}</div>
				<div class="info-row">快递公司： {{ details.logistics_company }}</div>
				<div class="info-row">快递单号： {{ details.logistics_code }} <span class="copy" v-clipboard:copy="details.logistics_code" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</span> </div>
			</template>
			<div class="info-row" v-if="details.order_status == 4">收货时间： {{ details.receive_time }}</div>
		</div>
		<!-- 公众号二维码 -->
		<we-chat-public-q-r-code></we-chat-public-q-r-code>
	</div>
</template>
<style lang="scss" scoped>
	.order-details {
		background: #f6f6f6;
		box-sizing: border-box;
		min-height: 100vh;

		* {
			box-sizing: border-box;
		}

		.top {
			height: 1.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #e40011;
			color: #fff;
			font-size: .3rem;
			margin-bottom: .3rem;

			img {
				height: .7rem;
				margin-left: .2rem;
			}
		}

		.address-box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 3vw;
			height: 1.5rem;
			background: #fff;
			margin-bottom: .25rem;

			.address-img {
				width: .35rem;
			}

			.right-img {
				width: .25rem;
				opacity: 0;
			}

			.mid {
				width: 80vw;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: .28rem;

				p:first-child {
					margin-bottom: 5px;
				}

				span {
					font-size: .26rem;
					color: #8a8a8a;
					margin-left: 2em;
				}
			}
		}

		.goods-list {
			background: #fff;
			padding: .2rem 3vw;
			margin: 0.2rem;
			margin-bottom: .2rem;
			border-radius: 0.1rem;

			.goods-item {
				display: flex;
				align-items: center;
				justify-content: space-between;

				img {
					width: 24vw;
					height: 24vw;
					object-fit: cover;
				}

				.goods-info {
					width: calc(100% - 26vw);
					height: 24vw;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.goods-name {
						font-size: .28rem;
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.goods-spec {
						background: #f5f5f5;
						border-radius: 5px;
						padding: .1rem .15rem;
						font-size: .24rem;
						color: #848484;
						width: 2rem;
					}

					.goods-num {
						font-size: .24rem;
					}

					.goods-price {
						font-size: .24rem;
						color: #da1313;

						span {
							font-size: .3rem;
							font-weight: bold;
						}
					}
				}

			}
		}
		.total {
			margin: 0.2rem;
			border-radius: 0.1rem;
			overflow: hidden;
		}

		.total-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #fff;
			font-size: .28rem;
			height: 1rem;
			padding: 0 .2rem;
			border-bottom: #f1f1f1 solid 1px;
			&:last-child {
				border-bottom: none;
			}

			.right {
				color: #da1313;
			}
		}

		.info-box {
			background: #fff;
			padding: .2rem .3rem;
			border-radius: 0.1rem;
			width: 94vw;
			margin: 3vw 3vw .3rem;

			.info-box-title {
				font-size: .28rem;
				position: relative;
				margin-bottom: 0.2rem;
			}

			.info-box-title::before {
				position: absolute;
				content: '';
				background: #da1313;
				width: 4px;
				height: 16px;
				left: -8px;
				top: 2px;
				margin-bottom: 10px;
			}

			.info-row {
				font-size: .26rem;
				line-height: 2;
				.copy {
					width: 0.8rem;
					height: 0.38rem;
					background: #DA1313;
					border-radius: 0.05rem;
					color: #fff;
					text-align: center;
					line-height: 0.38rem;
					display: inline-block;
					margin-left: 0.2rem;
					font-size: 0.22rem;
				}
			}
		}
	}
</style>
<script>
import {
	getIntegralOrderDetails
} from "@/http/api";

export default {
	components: {},
	data() {
		return {
			order_id: this.$route.query.id,
			details: {},
			goods: {}
		};
	},
	mounted() {
		if ((this.userInfo = this.$login.isLogin(window.location.href))) {
			this.getIntegralOrderDetails();
		}
	},
	methods: {
		async getIntegralOrderDetails() {
			const res = await getIntegralOrderDetails({
				user_id: this.userInfo.user_id,
				order_id: this.order_id
			})
			this.details = res;
			this.goods = res.goods_lists;
		},
		// 监听复制成功
    onCopy(e) {
      this.$toast.success('复制成功')
    },
    // 监听复制失败
    onError(e) {
      this.$toast.fail('复制失败');
    },
	},
	computed: {
		statusInfo() {
      let orderStatus = this.details.order_status;
      switch(orderStatus){
        case 0:
          return {
            statusText: '已取消',
            icon: 'tranbg',
          }
        case 1:
          return {
            statusText: '待支付',
            icon: 'tranbg2',
          }
        case 2:
          return {
            statusText: '待发货',
            icon: 'tranbg3',
          }
        case 3:
          return {
            statusText: '待收货',
            icon: 'tranbg4',
          }
        case 4:
          return {
            statusText: '已完成',
            icon: 'tranbg1',
          }
        default:
          return {
            statusText: '未知',
            icon: 'tranbg',
          }
      }
    }
	},
	filters: {

	}
};
</script>
